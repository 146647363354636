import { carolJonesSaint, reverendBertJones } from '../../assets/images';
import BioListLink from './BioListLink';
import './Bios.css';

const BioList = () => (
  <div className="container">
    <h1>Biographies</h1>
    <h5>Read about the people who brought the ministry to life</h5>
    <div className="row justify-content-around">
      <BioListLink
        img={reverendBertJones}
        to="/bios/reverend-bert-jones"
        name="Reverend Bert Jones"
      />
      <BioListLink img={carolJonesSaint} to="/bios/carol-jones-saint" name="Carol Jones Saint" />
    </div>
  </div>
);

export default BioList;
