import './Home.css';
import { jonesFamily } from '../assets/images';

const Home = () => (
  <>
    <div className="bg-black text-center">
      <img className="family-picture" src={jonesFamily} alt="The Jones Family" />
    </div>
    <div className="container my-4">
      <div className="text-center border-bottom mb-2">
        <h1 className="welcome-text fw-bold">
          Welcome to
          <br />
          Home Sweet Home!
        </h1>
      </div>
      <p>
        Welcome to the official site for <i>A Visit With the Joneses</i>, we&apos;re so glad you
        could join us!
      </p>
      <p>
        <i>A Visit With the Joneses</i> is a radio program created by the Jones family to share
        their talents in the name of winning people to Jesus. The radio ministry has been running
        for over 60 years and reaches a wide audience spanning several continents.
      </p>
      <p>
        Take a look around our site. <a href="/bios">Read</a> about the people who brought the show
        to life with their voices.
      </p>
      <p>Keep checking back, we&apos;re always working on more content to add to this website.</p>
    </div>
  </>
);

export default Home;
