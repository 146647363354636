import BioBody from '../BioBody';
import BioImage from '../BioImage';
import BioName from '../BioName';
import { reverendBertJones } from '../../../assets/images';

const BertJones = () => (
  <div className="m-4">
    <div className="text-center">
      <BioImage src={reverendBertJones} alt="An image" />
      <BioName name="Reverend Bert L. Jones" />
    </div>
    <BioBody>
      <p>Bio about Bert</p>
    </BioBody>
  </div>
);

export default BertJones;
