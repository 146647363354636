import BertJones from './components/bios/people/BertJones';
import BioList from './components/bios/BioList';
import CarolJonesSaint from './components/bios/people/CarolJonesSaint';
import Home from './components/Home';
import Navigation from './components/Navigation';
import NotFound from './components/NotFound';
import { Routes, Route } from 'react-router-dom';
import DevotionalList from './components/devotionals/DevotionalList';
import DevotionalEntry from './components/devotionals/DevotionalEntry';

const App = () => {
  return (
    <>
      <Navigation />
      <Routes>
        <Route path="bios">
          <Route path="reverend-bert-jones" element={<BertJones />} />
          <Route path="carol-jones-saint" element={<CarolJonesSaint />} />
          <Route index element={<BioList />} />
        </Route>
        <Route path="devotionals">
          <Route path=":id" element={<DevotionalEntry />} />
          <Route index element={<DevotionalList />} />
        </Route>
        <Route index element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
};

export default App;
