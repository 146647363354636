import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import devotions from './devotionals.json';

const DevotionalEntry = () => {
  const { id } = useParams();
  const [devotional, setDevotional] = useState({});
  useEffect(() => {
    setDevotional(devotions.devotionals.filter((d) => d.id === id)[0]);
  });

  return (
    <Container className="p-4">
      <Row className="border-bottom">
        <Col lg="12" className="text-center p-4">
          <h1>{devotional?.title}</h1>
        </Col>
        {devotional?.subtitle && (
          <Col className="text-center p-2">
            <h5 dangerouslySetInnerHTML={{ __html: devotional.subtitle }}></h5>
          </Col>
        )}
      </Row>
      <Row>
        <Col className="p-4 text-14" dangerouslySetInnerHTML={{ __html: devotional?.body }}></Col>
      </Row>
    </Container>
  );
};

export default DevotionalEntry;
