import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import devotionsJson from './devotionals.json';

const DevotionalList = () => {
  const [devotions, setDevotions] = useState([]);
  useEffect(() => {
    setDevotions(devotionsJson.devotionals);
  }, []);
  return (
    <Container>
      <Row className="mb-4">
        <Col>
          <h1>Devotionals</h1>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <h5>Encouraging words from our hearts to yours</h5>
        </Col>
      </Row>
      {devotions.length > 0
        ? devotions.map((d) => (
            <Row key={d.id}>
              <Col className="mb-3">
                <Link to={'/devotionals/' + d.id} className="text-decoration-none text-dark">
                  <Container className="border rounded p-4">
                    <Row>
                      <Col className="d-flex justify-content-between">
                        <h4>{d.title}</h4>
                        <p>{d.date || new Date().toString('MM/dd/yyyy')}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>{d.body.replace(/<\/?[pi]+>/g, '').substring(0, 50) + '...'}</Col>
                    </Row>
                  </Container>
                </Link>
              </Col>
            </Row>
          ))
        : 'Check back later to see our collection of devotions'}
    </Container>
  );
};

export default DevotionalList;
