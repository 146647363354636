import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem } from 'reactstrap';

const Navigation = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <div className="bg-dark">
      <Navbar color="dark" dark expand="md" className="container">
        <NavbarBrand
          tag={Link}
          to="/"
          className="text-decoration-none"
          style={{ fontFamily: "'Caveat', cursive", fontSize: '18pt' }}>
          A Visit With The Joneses
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ms-auto" navbar>
            <NavItem>
              <NavLink
                tag={Link}
                to="/bios"
                className="text-decoration-none text-light hoverable p-2">
                Bios
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                tag={Link}
                to="/devotionals"
                className="text-decoration-none text-light hoverable p-2">
                Devotionals
              </NavLink>
            </NavItem>
            <NavItem>
              <a
                href="https://www.youtube.com/channel/UCNylpYoOv9wTBj2SISuSUEg/playlists"
                target="_blank"
                className="text-decoration-none text-light hoverable p-2"
                rel="noreferrer">
                Listen
              </a>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Navigation;
