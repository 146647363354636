/* eslint-disable react/prop-types */

import { Link } from 'react-router-dom';

const BioListLink = ({ to = '/', name = 'No Name', img, alt }) => (
  <div className="col-md-4 text-center">
    <Link to={to} className="text-decoration-none text-dark">
      <div className="rounded">
        <img src={img} className="link-picture rounded mb-2" alt={alt} />
        <h5>{name}</h5>
      </div>
    </Link>
  </div>
);

export default BioListLink;
