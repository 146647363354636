import BioBody from '../BioBody';
import BioImage from '../BioImage';
import BioName from '../BioName';
import { carolJonesSaint } from '../../../assets/images';

const CarolJonesSaint = () => (
  <div className="m-4">
    <div className="text-center">
      <BioImage src={carolJonesSaint} alt="Image of Carol Jones Saint" />
      <BioName name="Carol Jones-Saint" />
    </div>
    <BioBody>
      <p>Carol resides in Erie, Pennsylvania along the lake shore.</p>
    </BioBody>
  </div>
);

export default CarolJonesSaint;
